/* print.css */



.fff {
  @media print {

    /* Adjust margins for the second page */
    @page :first {
      /* margin-top: '47px';
    size: 'A4 portrait';
    margin-left: '96px';
    margin-right: '60px';
    margin-bottom: '28px';
    */
      /* Set margins for the first page */
      margin: 0.65in;
      size: 'A4 portrait';
    }

    @page {
      /* margin: '47px'; */
      size: 'A4 portrait';
      margin: 0.5in;
      /* display: block; */
      /* Set margins for the second and subsequent pages */
    }

    body {
      /* Additional styles for printing */
      font-size: 12pt;
    }
  }
}