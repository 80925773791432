/* thai */
@font-face {
    font-family: 'Sarabun';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url(Sarabun-ThinItalic.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

/* thai */
@font-face {
    font-family: 'Sarabun';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url(Sarabun-ExtraLightItalic.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

/* thai */
@font-face {
    font-family: 'Sarabun';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(Sarabun-LightItalic.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

/* thai */
@font-face {
    font-family: 'Sarabun';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(Sarabun-Italic.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

/* thai */
@font-face {
    font-family: 'Sarabun';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url(Sarabun-MediumItalic.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

/* thai */
@font-face {
    font-family: 'Sarabun';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(Sarabun-SemiBoldItalic.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

/* thai */
@font-face {
    font-family: 'Sarabun';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(Sarabun-BoldItalic.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

/* thai */
@font-face {
    font-family: 'Sarabun';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url(Sarabun-ExtraBoldItalic.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

/* thai */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url(Sarabun-Thin.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

/* thai */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(Sarabun-ExtraLight.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

/* thai */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(Sarabun-Light.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

/* thai */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(Sarabun-Regular.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

/* thai */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(Sarabun-Medium.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

/* thai */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(Sarabun-SemiBold.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

/* thai */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(Sarabun-Bold.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

/* thai */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(Sarabun-ExtraBold.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Angsana';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: url('../Angsana/ANGSA.ttf') format('truetype');
}